import React from 'react';
import styled from 'styled-components';

const StackDiv = styled.div`
    width: 100%;
    min-height: 65px;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 1px 0 1px rgba(0,0,0,.02),0 4px 6px rgba(0,0,0,.02),inset 0 0 0 6px #111;
    background: linear-gradient(180deg,#242424,#121212 65.62%);
    position: relative;
    transition: background .15s ease;
    margin-bottom: -30px;
    display: flex;
    align-items: center;
    justify-content: center;


    &:before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: inherit;
        padding: 1.2px;
        -webkit-mask: linear-gradient(#fff 0 0) content-box,linear-gradient(#fff 0 0);
        mask: linear-gradient(#fff 0 0) content-box,linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
        background: conic-gradient(from 180deg at 50% 50%,#333  0deg,#333 176deg,#61dafb 193deg,#333 217deg,#333  1turn);
    }

    @media screen and (max-width:600px) {
        margin-bottom: 30px;
        width: 300px; // Not being applied 
        height: 40px;
    }

`;

const StyledText = styled.span`
    font-size: 2rem;
    background: linear-gradient(92.66deg,#919191,#eaeaea 19.79%,#828181 33.33%,#c6c6c6 70.31%,#606060);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 10px hsla(0,0%,100%,.1), 0 -1px 0 rgba(0,0,0,.1);
    text-transform: uppercase;

    @media screen and (max-width:600px) {
        font-size: 1.4rem;
    }

`

export default function CpuShine() {
    return (
        <StackDiv >
            <StyledText>ALGOFLOW</StyledText>
        </StackDiv>
    );
}
