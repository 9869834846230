import { motion } from 'framer-motion';
import React from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import styled from 'styled-components';
import TranslateText from '../../utils/translateText';
import { DetailedText, Title, TitleSmall, redColor } from '../styledComponents';
import CpuShine from './components/cpuShine';
import WaveAnimation from './components/waveAnimation';

const OuterDiv = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  align-items:center;
  width: 100%;
  min-height: 90vh;   


  @media screen and (max-width:1000px) {
    min-height: calc(100vh - 3rem);
  }
`;

const UpperDiv = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  align-items:center;
  width: 100%;
  max-width: 1350px;
  margin-top: 1.5rem;

  @media screen and (max-width:1355px) {
      width: 93vw;
  }

  @media screen and (max-width:1100px) {
    margin-top:1rem;
    width: 90vw;
  }
`;

const RowWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content:center;
  align-items:center;
  width: 100%;
  height: fit-content;
  overflow: visible;
  @media screen and (max-width:1200px) {
       //box-sizing: border-box;
       margin-top:3rem;
  }
`;

const LogoDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content:center;
  align-items:center;
  width:250px;
  height:160px;
  background-image: inherit;
  z-index: 10;

  //margin: 0 auto; /* centers the div horizontally */
  //transform: rotateY(0deg) rotate(-100deg); /* needs Y at 0 deg to behave properly*/
  transition: transform 2s;

  @media screen and (max-width:800px) {
       //box-sizing: border-box;
       width: 35vw;
  }
`;

const TitleBottom = styled.h5`
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 0;
  margin: 0;
  color:#f1f1f1;
`;

const KeepReading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:flex-end;
  align-items:center;
  width:250px;
  height:100%;
  flex:1;
  padding-bottom: 10vh;
  box-sizing: border-box;

  @media screen and (max-width:600px) {
    padding-bottom: 7vh;  
  }
`;

const StyledIoIosArrowForward = styled(IoIosArrowForward)`
  color: ${redColor};
  font-size: 24px;
  transform: rotate(90deg);
`;

const KeepReadingInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:flex-end;
  align-items:center;
  width:auto;
  height:auto;
  padding:10px;

  &:hover{
    scale: 1.03;
    cursor: pointer;
  }
`;

function HowItWorks({ scrollToNext }) {

  return (
    <OuterDiv >
      <UpperDiv>
        <TitleSmall >
          <TranslateText swedish={'Vad gör Paretro'} english={'What is AlgoFlow?'} />
        </TitleSmall>
        <Title>
          <TranslateText swedish={'Samlar, hanterar och transformerar data'} english={'Collects, manages, and processes data'} />
        </Title>
        <DetailedText>
          <TranslateText
            swedish={'Datan samlas via befintliga system, sensorer, IoT-enheter och manuella input.'}
            english={'We collect all data from your business through existing sources, sensors, and IoT devices. '}
          />
          <br />
          <TranslateText
            swedish={'Därefter transformerar vi datan för att skapa direkta insikter och applikationer.'}
            english={'Then we transform the data to create direct insights and applications.'}
          />
        </DetailedText>
      </UpperDiv>

      <RowWrap>
        <WaveAnimation reverseDirection={false} />
        <LogoDiv>
          <CpuShine />
        </LogoDiv>
        <WaveAnimation reverseDirection={true} />
      </RowWrap>
      <KeepReading>
        <KeepReadingInner onClick={() => scrollToNext()}>
          <TitleBottom>
            <TranslateText swedish={'Fortsätt'} english={'Continue'} />
          </TitleBottom>
          <StyledIoIosArrowForward />
        </KeepReadingInner>
      </KeepReading>
    </OuterDiv>
  )
}

export default HowItWorks;